<template>
    <div class="m-5">
      <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search Vehicle</label>
      <div class="relative mt-2 flex items-center">
        <input
            type="text"
            name="search"
            id="search"
            class="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            v-model="keyword"
            v-on:keyup="search"
            v-on:keydown="search"
            placeholder="unit number, vehicle make/model/year/color, state/license plate, and vin number"
        >
        <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5" v-if="keyword === ''">
          <a class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400" href="">
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
        </div>
        <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5" v-if="keyword !== ''">
          <a class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400" @click="resetSearch">
            <i class="fa-solid fa-x"></i>
          </a>
        </div>
      </div>

        <div class="mb-10 mt-2">
          <ul role="list" class="divide-y divide-gray-100 bg-white p-5 mb-1">
            <li class="flex items-center justify-between gap-x-6 py-5 bg-white-500" v-for="(vehicle) in vehicleListResult" :key="vehicle.id">
              <a href="#" @click="selectVehicle(vehicle)">
              <div class="flex min-w-0 gap-x-4">
                <div class="mr-4 flex-shrink-0 self-center text-2xl text-green-800">
                  <img class="w-24 text-gray-800 vehicle-image" :src="(vehicle.image == null || vehicle.image === '' ? 'img/no-image.png' : vehicle.image)" />
                </div>
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                    {{ vehicle.unit_number}} - {{ vehicle.year }}
                    {{ vehicle.make }}
                    {{ vehicle.model }}
                  </p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                    {{ vehicle.state_registered}} {{ vehicle.license_plate_number}}
                  </p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                    {{ vehicle.vin_number}}
                  </p>
                </div>
              </div>
              </a>
            </li>
          </ul>
        </div>

      <div class="m-10 text-center text-red-600 font-bold" v-if="isSearch && resultCount <= 0">
        No record found
      </div>
    </div>
</template>

<script>
export default {
  name: 'SearchPanel',
  data() {
    return {
      isSearch: false,
      keyword: '',
      vehicleList: [],
      vehicleListResult: [],
      resultCount: 0,
    }
  },
  methods: {
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    selectVehicle(vehicle) {
      localStorage.setItem('selectedVehicle', JSON.stringify(vehicle))
      this.$store.dispatch('vehicle/selected')
      this.$router.push('/dashboard')
    },
    wildcardToRegex(pattern) {
      return new RegExp(pattern.split('*').join('.*') + '$');
    },
    searchInJson(json, pattern) {
      const regex = this.wildcardToRegex(pattern.toLowerCase());
      this.vehicleListResult = []
      const t = this
      console.log(regex)
      function recursiveSearch(obj) {
        for (const key in obj) {
          console.log(typeof obj[key])
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            recursiveSearch(obj[key]);
          } else {
            if (obj[key] !== null) {
              console.log('OBJ1: ' + obj[key])
              console.log('REGEX: ' + regex)
              console.log('TEST: '+ regex.test("'" + obj[key] + "'"))
              if (regex.test(obj[key].toString().toLowerCase())) {
                console.log('OBJ: ' + JSON.stringify(obj[key]))
                t.vehicleListResult.push(obj);
              }
            }
          }
        }
      }

      json.forEach(obj => {
        recursiveSearch(obj);
      });
      t.vehicleListResult = t.getUniqueListBy(t.vehicleListResult, 'id')
    },
    search() {
      if (this.keyword !== '') {
        const pattern = this.keyword + "*";
        this.vehicleListResult = this.vehicleList
        this.searchInJson(this.vehicleListResult, pattern)
      } else {
        console.log('KEYWORD: ' + this.keyword)
        this.vehicleListResult = this.vehicleList;
        this.isSearch = false
        this.resultCount = 0
      }
      // console.log('MATCH: ' + matchedValues); // Output: ["John Doe"]
      /*if (this.keyword !== '') {
        const t = this
        // setTimeout(function () {
          t.vehicleListResult = []
          t.vehicleList.forEach(function(vehicle) {
            //  unit number, vehicle make/model/year/color, the state/license plate, and vin number.
            if ((vehicle.unit_number ? vehicle.unit_number : '').toLowerCase().indexOf(t.keyword.toLowerCase()) !== -1
                || (vehicle.make ? vehicle.make : '').toLowerCase().indexOf(t.keyword.toLowerCase()) !== -1
                || vehicle.year === t.keyword
                || (vehicle.license_plate_number ? vehicle.license_plate_number : '').toLowerCase() === t.keyword.toLowerCase()
                || (vehicle.state_registered ? vehicle.state_registered : '').toLowerCase() === t.keyword.toLowerCase()
                || vehicle.vin_number === t.keyword
                || (vehicle.model ? vehicle.model : '').toLowerCase() === t.keyword.toLowerCase()
            ) {
              t.vehicleListResult.push(vehicle)
            }
          });
          t.vehicleListResult = t.getUniqueListBy(t.vehicleListResult, 'id')
          t.resultCount = t.vehicleListResult.length
          t.isSearch = true
        // }, 500)

      } else {
        this.vehicleListResult = this.vehicleList;
        this.isSearch = false
        this.resultCount = 0
      }*/
    },
    resetSearch() {
      this.keyword = '';
      this.vehicleListResult = this.vehicleList;
      this.isSearch = false
      this.resultCount = 0
    }
  },
  created() {
    this.vehicleList = JSON.parse(localStorage.getItem('vehicleList'))
    this.vehicleListResult = this.vehicleList
    const t = this
    t.vehicleListResult = []
    t.vehicleList.forEach(function(vehicle) {
      //  unit number, vehicle make/model/year/color, the state/license plate, and vin number.
      if (!vehicle.rental) {
        t.vehicleListResult.push(vehicle)
      }
    });
    t.vehicleListResult = t.getUniqueListBy(t.vehicleListResult, 'id')
    t.resultCount = t.vehicleListResult.length
    t.isSearch = true
  },
  mounted() {
    //
  }
}
</script>