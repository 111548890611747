<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm m-5">
    <div class="mt-5 p-5 bg-white">
      <form>
        <div class="">
          <div class="" v-if="!$parent.isCertifyPage">
            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Location</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name="gps"
                        id="gps"
                        v-model="$parent.gps"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="GPS Coordinates"
                        disabled
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Odometer</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="number"
                        name="odometer"
                        id="odometer"
                        v-model="$parent.odometer"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder=""
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Inspection Type</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <fieldset class="p-0 mt-6">

                    <div class="grid grid-cols-2 gap-6 text-center">
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
                          :class="$parent.preTrip ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input type="radio" name="memory-option" v-model="$parent.preTrip" class="sr-only" aria-labelledby="memory-option-0-label" @click="checkInspectionType('pre')">
                        <span id="memory-option-0-label">Pre-trip</span>
                      </label>
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
                          :class="$parent.postTrip ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input type="radio" name="memory-option" v-model="$parent.postTrip" class="sr-only" aria-labelledby="memory-option-1-label" @click="checkInspectionType('post')">
                        <span id="memory-option-1-label">Post-trip</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Work Around Photos</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0 mb-5">
                  <div class="text-center">
                    <div>
                      <ul role="list" class="mt-3 grid grid-cols-4 sm:grid-cols-4 sm:gap-1 lg:grid-cols-4">
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-12 items-center text-sm font-medium">
                            <label for="upload-button">
                              <input id="front" accept="image/*" capture="environment" name="front" type="file" hidden @change="renderImage('image-front', 'front')">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                                  @click="chooseFiles('front')"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-front" class="max-h-85 max-w-70 object-contain invisible h-0" src="#" v-if="isFrontImageSelected" width="70" height="85"/>
                                  <i class="fa-solid fa-camera" v-if="!isFrontImageSelected"></i>
                                </span>
                                Front
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <input id="driver" accept="image/*" capture="environment" name="driver" type="file" hidden @change="renderImage('image-driver', 'driver')">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                                  @click="chooseFiles('driver')"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-driver" class="max-h-85 max-w-70 object-center invisible h-0" src="#" v-if="isDriverImageSelected" />
                                  <i class="fa-solid fa-camera" v-if="!isDriverImageSelected"></i>
                                </span>
                                Driver
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <input id="back" name="back" accept="image/*" capture="environment"  type="file" hidden @change="renderImage('image-back', 'back')">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                                  @click="chooseFiles('back')"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-back" class="max-h-85 max-w-70 object-center invisible h-0" src="#" v-if="isBackImageSelected"  width="70" height="85"/>
                                  <i class="fa-solid fa-camera" v-if="!isBackImageSelected"></i>
                                </span>
                                Back
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <input id="passenger" accept="image/*" capture="environment"  name="passenger" type="file" hidden @change="renderImage('image-passenger', 'passenger')">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                                  @click="chooseFiles('passenger')"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-passenger" class="max-h-85 max-w-70 object-center invisible h-0" src="#" v-if="isPassengerImageSelected"  width="70" height="85"/>
                                  <i class="fa-solid fa-camera" v-if="!isPassengerImageSelected"></i>
                                </span>
                                Passenger
                              </button>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Vehicle Defects</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <h3>Any vehicle attributes not lister below are certified safe by <strong>The Driver</strong></h3>
                  <div class="sm:max-w-md mt-5">
                    <ul role="list" class="divide-y divide-gray-100">
                      <li class="py-4" v-for="(defect, index) in $parent.addedDefects" :key="index">
                        <div class="flex items-center gap-x-3">
                          <i class="fa-sharp fa-regular h-6 w-6 fa-square-xmark text-red-600" @click="removeDefect(defect.dvir_defect_type_id)"></i>
                          <img :src="'data:image/png;base64,' + defect.document_file" alt="" class="h-6 w-6 flex-none rounded-full bg-gray-800" v-if="defect.document_file !== null">
                          <i class="fa-solid fa-pencil text-green-600" v-if="defect.document_file === null"></i>
                          <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{{ defect.typeName }}</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="focus-within:ring-indigo-600 sm:max-w-md mt-5 text-center">
                    <button
                        type="button"
                        class="rounded-full bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="$parent.addDefect"
                    >
                      <i class="fa-solid fa-plus"></i>
                      Add Defect
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Choose Safety Status</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <fieldset class="p-0 mt-6">

                    <div class="grid grid-cols-2 gap-6 text-center">
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1 cursor-pointer focus:outline-none"
                          :class="$parent.safeToDrive ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input type="radio" name="memory-option" value="4 GB" class="sr-only" aria-labelledby="memory-option-0-label" @click="checkSafeToDrive('safe')">
                        <span id="memory-option-0-label">Safe To Drive</span>
                      </label>
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1 cursor-pointer focus:outline-none"
                          :class="!$parent.safeToDrive && $parent.safeToDrive !== '' ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input type="radio" name="memory-option" value="8 GB" class="sr-only" aria-labelledby="memory-option-1-label" @click="checkSafeToDrive('not')">
                        <span id="memory-option-1-label">Not Safe To Drive</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <CertifyPanel v-if="$parent.isCertifyPage"/>

          <ErrorAlert v-if="$store.state.isError"/>
          <div class="text-center py-10" v-if="!$parent.isCertifyPage">
            <button
                type="button"
                @click="certifySafe"
                class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
                :disabled="$store.state.isProcessing"
            >
              Next
            </button>
          </div>

          <div class="text-center py-10" v-if="$parent.isCertifyPage">
            <button
                id="certifyBtn"
                type="button"
                @click="$parent.submitDvir"
                class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
                :disabled="$store.state.isProcessing"
            >
              {{ ($store.state.isProcessing ? 'Processing...' : 'Certify and Submit') }}
            </button>
          </div>
        </div>
      </form>
    </div>


  </div>
</template>

<script>
import CertifyPanel from "@/components/Dvir/Certify.vue";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
import heic2any from "heic2any";
// import router from "../../router/index";

export default {
  name: 'CreateDvirPanel',
  data() {
    return {
      showAddDefect: false,
      isCertifyPage: false,
      gps: 0,
      odometer: '',
      postTrip: '',
      preTrip: '',
      inspectionType: 0,
      safeToDrive: this.$parent.safeToDrive,
      newDvir: {},

      isShowCamera: false,
      isFrontImageSelected: false,
      isDriverImageSelected: false,
      isBackImageSelected: false,
      isPassengerImageSelected: false,

      // addedDefects: [],

      categ: [],
      showDefect: false,
      imageId: 0,

    }
  },
  components: {ErrorAlert, CertifyPanel},
  methods: {
    resetForm()
    {
      const t = this
      t.showAddDefect = false
      t.$parent.isCertifyPage = false
      t.gps = 0
      t.odometer = ''
      t.postTrip = ''
      t.preTrip = ''
      t.inspectionType = 0
      t.safeToDrive = ''
      t.newDvir = {}
      t.isShowCamera = false
      t.isFrontImageSelected = false
      t.isDriverImageSelected = false
      t.isBackImageSelected = false
      t.isPassengerImageSelected = false
      t.$parent.addedDefects = []
      t.showDefect = false

      //TODO: Remove some values in local storage
      localStorage.removeItem('dvirImage_front'),
      localStorage.removeItem('dvirImage_driver'),
      localStorage.removeItem('dvirImage_back'),
      localStorage.removeItem('dvirImage_passenger')

      t.categ.forEach(function (cat) {
        cat.types.forEach(function(type){
          localStorage.removeItem('defects')
          localStorage.removeItem('selectedDvir')
          localStorage.removeItem('xyCoordinates_'+type.id)
          // localStorage.removeItem('imageDefect'+ type.id)
          localStorage.removeItem('defectArea_'+ type.id)
        })
      });

    },
    checkInspectionType(selected) {
      this.$parent.preTrip = 1
      this.$parent.postTrip = 0
      this.$parent.inspectionType = ''

      if (selected === 'post') {
        this.$parent.postTrip = 1
        this.$parent.preTrip = 0
        this.$parent.inspectionType = 'Post-trip'
      }

      if (selected === 'pre') {
        this.$parent.preTrip = 1
        this.$parent.postTrip = 0
        this.$parent.inspectionType = 'Pre-trip'
      }
    },
    checkSafeToDrive(selected) {
      this.$parent.safeToDrive = ''
      if (selected === 'safe') {
        this.$parent.safeToDrive = 1
      }

      if (selected === 'not') {
        this.$parent.safeToDrive = 0
      }
    },
    chooseFiles(fileInput) {
      document.getElementById(fileInput).click()
    },
    renderImage(id, fileInput) {
      const [file] = document.getElementById(fileInput).files
      console.log(file)

      const t = this
      if (file) {
        t.imageId = t.imageId + 1
        if (fileInput === 'front') {
          this.isFrontImageSelected = true
          t.imageId = 1
        }

        if (fileInput === 'driver') {
          this.isDriverImageSelected = true
          t.imageId = 2
        }

        if (fileInput === 'back') {
          this.isBackImageSelected = true
          t.imageId = 3
        }

        if (fileInput === 'passenger') {
          this.isPassengerImageSelected = true
          t.imageId = 4
        }

        setTimeout(async function () {
          let convertedBlob = file;
          console.log(file.type)
          if (file.type === 'image/heif' || file.type === 'image/heic') {
            convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
          }

          let selectedImage = document.getElementById(id)
          selectedImage.src = URL.createObjectURL(convertedBlob)

          selectedImage.classList.remove('invisible')
          selectedImage.classList.remove('h-0')
          selectedImage.classList.add('defectImage')

          const reader = new FileReader()
          reader.readAsDataURL(convertedBlob);
          reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1]; // Extract the Base64 part
            //localStorage.setItem('dvirImage_'+ fileInput, base64String);
            t.$parent.saveImageToLocal(fileInput, base64String)
          };
          // reader.readAsDataURL(file)
        }, 1000)
      }
    },
    addDefect() {
      document.getElementById('slideover-container').classList.toggle('invisible');
      document.getElementById('slideover').classList.toggle('translate-x-full');
    },
    removeDefect(typeId) {
      for (let [i, addedDefect] of this.$parent.addedDefects.entries()) {
        if (addedDefect.dvir_defect_type_id === typeId) {
          this.$parent.addedDefects.splice(i, 1);
        }
      }
      localStorage.setItem('defects', JSON.stringify(this.$parent.addedDefects))

      document.getElementById('description_' + typeId).value = ''
      localStorage.removeItem('imageDefect'+ typeId)
      localStorage.removeItem('xyCoordinates_'+typeId)
    },
    certifySafe() {
      this.$store.state.isError = false
      this.$store.state.errorMsg = ''

      // Validate required fields
      if (this.$parent.odometer === '' || this.$parent.odometer == 0) {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Odometer is required.'
        return
      }

      // console.log(this.$store.state.vehicle.selectedVehicle.odometer)
      if (this.$parent.odometer <= this.$store.state.vehicle.selectedVehicle.odometer) {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Odometer must higher than the current odometer reading.'
        return
      }

      if (this.$parent.inspectionType === '') {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Inspection type is required.'
        return
      }

      if (this.$parent.safeToDrive === '') {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Safety status is required.'
        return
      }

      this.safeToDrive = this.$parent.safeToDrive
      this.$store.dispatch('setPageTittle', 'Certify and Submit', '/vehicle-dvir-create?reset=0')
      this.$store.dispatch('setParentPage', '/vehicle-dvir-create?reset=0')
      this.$store.state.isRefresh = false
      this.$store.state.parentPageTitle = 'Create DVIR'

      this.$parent.isCertifyPage = true
    },
    getCoordinates() {
      const t = this
      function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        t.$parent.gps = latitude +','+ longitude
      }
      function error() {
        console.log("Unable to retrieve your location");
        // alert('This app would like to access your location.');
        // window.location.href ='';
        t.setDefaultLocation();
      }

      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
      } else {
        console.log("Locating…");
        navigator.geolocation.getCurrentPosition(success, error);
      }
    },
    async setDefaultLocation() {
      const t = this
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        t.$parent.gps = data.latitude + ',' + data.longitude
      } catch (error) {
        console.error('Could not fetch default location', error);
        // Set a fallback default location (e.g., a city center)
        t.$parent.gps = '40.7128,-74.0060'
      }
    }
  },
  created() {
    console.log('created - create dvir')

    // this.$store.dispatch('dvir/getDvirDefectCategories', {})
    // this.categ = JSON.parse(localStorage.getItem('defectCategories'))

    const urlParams = new URLSearchParams(window.location.search);
    const isReset = urlParams.get('reset');
    console.log('IS_RESET: '+ isReset)

    if (isReset == 1 || isReset == null) {
      console.log('reset form')
      this.resetForm()
    }

    if (localStorage.getItem('defects') !== null) {
      this.$parent.addedDefects = JSON.parse(localStorage.getItem('defects'))
    }

  },
  mounted() {
    this.getCoordinates()
  }
}
</script>

<style>
.dvirImage {
  max-height: 50px !important;
  height: 50px !important;
  width: 70px !important;
}
</style>