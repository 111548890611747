<template>
  <HeaderPanel />
  <SelectedVehiclePanel />

  <SuccessAlert v-if="$store.state.isSuccess"/>
  <div class="justify-center px-6 py-8 lg:px-8">
    <MainMenu />
  </div>

</template>

<script>
import HeaderPanel from "@/components/Common/Header.vue";
import SelectedVehiclePanel from "@/components/Vehicle/Selected.vue";
import MainMenu from "@/components/Menu/Main.vue";
import SuccessAlert from "@/components/Common/SuccessAlert.vue";

export default {
  name: 'DashboardView',
  components: {
    SuccessAlert, MainMenu,
    SelectedVehiclePanel,
    HeaderPanel,
  },
  created() {
    let indexedDB = window.indexedDB
    if (indexedDB) {
      console.log("IndexedDB could not be found in this browser.")
    }
    indexedDB.deleteDatabase('DvirImage')
  },
  mounted() {
    this.$store.dispatch('vehicle/get')
  }
}
</script>