<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm m-5">
    <div class="mt-5 p-5 bg-white">
      <form>
        <div class="">
          <div class="" v-if="!isCertifyPage">
            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Location</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name="gps"
                        id="gps"
                        v-model="gps"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="GPS Coordinates"
                        disabled
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Odometer</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="number"
                        name="odometer"
                        id="odometer"
                        v-model="odometer"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder=""
                        disabled
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Inspection Type</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <fieldset class="p-0 mt-6 text-center">

                    <div class="grid grid-cols-2 gap-6">
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
                          :class="preTrip ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input
                            type="radio"
                            name="memory-option"
                            v-model="preTrip"
                            class="sr-only"
                            aria-labelledby="memory-option-0-label"
                            @click="checkInspectionType('pre')"
                            disabled
                        >
                        <span id="memory-option-0-label">Pre-trip</span>
                      </label>
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
                          :class="postTrip ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input
                            type="radio"
                            name="memory-option"
                            v-model="postTrip"
                            class="sr-only"
                            aria-labelledby="memory-option-1-label"
                            @click="checkInspectionType('post')"
                            disabled
                        >
                        <span id="memory-option-1-label">Post-trip</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Work Around Photos</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0 mb-5">
                  <div class="text-center">
                    <div>
                      <ul role="list" class="mt-3 grid grid-cols-4 sm:grid-cols-4 sm:gap-1 lg:grid-cols-4">
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-12 items-center text-sm font-medium">
                            <label for="upload-button">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-front" class="max-h-85 max-w-60 object-contain dvirImage" :src="(frontImageFile != null ? frontImageFile : 'img/no-image.png')"  width="60" height="85"/>
                                </span>
                                Front
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-driver" class="max-h-85 max-w-70 object-center dvirImage" :src="(driverImageFile != null ? driverImageFile : 'img/no-image.png')"  width="60" height="85"/>
                                </span>
                                Driver
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-back" class="max-h-85 max-w-70 object-center dvirImage" :src="(backImageFile != null ? backImageFile : 'img/no-image.png')"  width="60" height="85" />
                                </span>
                                Back
                              </button>
                            </label>
                          </div>
                        </li>
                        <li class="col-span-1 flex rounded-md shadow-sm">
                          <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                            <label for="upload-button">
                              <button
                                  type="button"
                                  class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                              >
                                <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                                  <img id="image-passenger" class="max-h-85 max-w-70 object-center dvirImage" :src="(passengerImageFile != null ? passengerImageFile : 'img/no-image.png')"  width="60" height="85"/>
                                </span>
                                Passenger
                              </button>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Vehicle Defects</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <h3>Any vehicle attributes not lister below are certified safe by <strong>{{ driverName }}</strong></h3>
                  <div class="sm:max-w-md mt-5">
                    <ul role="list" class="divide-y divide-gray-100">
                      <li class="py-4" v-for="(defect, index) in addedDefects" :key="index">
                        <div class="flex items-center gap-x-3">
                          <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{{ defect.defect_type.name }} </h3>
                          <p class="text-red-600 text-xs italic" v-if="defect.resolution_date == null">First reported on {{ formatDate(defect.updated_at)}}</p>
                        </div>
                        <p class="mt-3 truncate text-sm text-gray-500">
                          <strong>Description:</strong> {{ defect.description}}
                        </p>
                        <a href="javascript:void(0)" class="text-red-600 text-xs" @click="previewImage(defect.dvir_defect_type_id)">Tap here to see the defect area</a>

                        <div class="mt-3" v-if="defect.document_file !== null">
                          <img :src="defect.document_file" alt="No image" title="Top view vehicle" class="m-5 max-h-80 max-w-100 h-70"  height="70"  @click="previewImage(defect.dvir_defect_type_id)"/>
                        </div>

<!--                        <div class="mt-3" v-if="defect.document_file === null">
                          <img src="/img/no-image.png" alt="No image" title="Top view vehicle" class="m-5" />
                        </div>-->

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Choose Safety Status</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <fieldset class="p-0 mt-6 text-center">

                    <div class="grid grid-cols-2 gap-6">
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1 cursor-pointer focus:outline-none"
                          :class="safeToDrive ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input
                            type="radio"
                            name="memory-option"
                            value="4 GB"
                            class="sr-only"
                            aria-labelledby="memory-option-0-label"
                            @click="checkSafeToDrive('safe')"
                            disabled
                        >
                        <span id="memory-option-0-label">Safe To Drive</span>
                      </label>
                      <label
                          class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1 cursor-pointer focus:outline-none"
                          :class="!safeToDrive ? 'bg-green-600 text-white hover:bg-green-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50'"
                      >
                        <input
                            type="radio"
                            name="memory-option"
                            value="8 GB"
                            class="sr-only"
                            aria-labelledby="memory-option-1-label"
                            @click="checkSafeToDrive('not')"
                            disabled
                        >
                        <span id="memory-option-1-label">Not Safe To Drive</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Started At</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name="startedAt"
                        id="startedAt"
                        v-model="startedAt"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder=""
                        disabled
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Submitted At</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name="submittedAt"
                        id="submittedAt"
                        v-model="submittedAt"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder=""
                        disabled
                    >
                  </div>
                </div>
              </div>
            </div>

          </div>

          <CertifyPanel v-if="isCertifyPage"/>

          <div class="text-center py-10" v-if="!isCertifyPage">
            <button
                type="button"
                @click="close"
                class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4">
              Close
            </button>
          </div>

          <DefectPanel/>
        </div>
      </form>
    </div>
  </div>

  <ImagePreview v-if="showPreview" :isCreate="false"/>

</template>

<script>
import CertifyPanel from "@/components/Dvir/Certify.vue";
import DefectPanel from "@/components/Dvir/Defect.vue";
import ImagePreview from "@/components/Common/ImagePreview.vue";

export default {
  name: 'ViewDvirPanel',
  data() {
    return {
      showAddDefect: false,
      isCertifyPage: false,
      gps: 0,
      odometer: 0,
      postTrip: 0,
      preTrip: 1,
      inspectionType: 0,
      safeToDrive: 1,
      frontImageFile: '',
      driverImageFile: '',
      backImageFile: '',
      passengerImageFile: '',
      driverName: '',
      startedAt: '',
      submittedAt: '',
      newDvir: {},

      isShowCamera: false,
      isFrontImageSelected: false,
      isDriverImageSelected: false,
      isBackImageSelected: false,
      isPassengerImageSelected: false,

      addedDefects: [],

      categ: [],
      showDefect: false,
      selectedDefectType: 0,
      showPreview: false
    }
  },
  components: {ImagePreview, DefectPanel, CertifyPanel},
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
    checkInspectionType(selected) {
      this.preTrip = 1
      this.postTrip = 0
      this.inspectionType = 'Pre-trip'

      if (selected === 'post') {
        this.postTrip = 1
        this.preTrip = 0
        this.inspectionType = 'Post-trip'
      }
    },
    checkSafeToDrive(selected) {
      this.safeToDrive = 0
      if (selected === 'safe') {
        this.safeToDrive = 1
      }
    },
    close() {
      window.location.href = '/vehicle-details'
    },
    previewImage(typeId) {
      this.showPreview = true
      this.selectedDefectType = typeId
    },
  },
  created() {
    //
  },
  mounted() {

    // Set dvir information
    console.log('safe: ' + this.$parent.selectedDvir.safe_to_drive)
    this.gps = this.$parent.selectedDvir.gps
    this.odometer = this.$parent.selectedDvir.odometer
    this.safeToDrive = this.$parent.selectedDvir.safe_to_drive
    this.frontImageFile = this.$parent.selectedDvir.front_image_file
    this.driverImageFile = this.$parent.selectedDvir.driver_side_image_file
    this.backImageFile = this.$parent.selectedDvir.back_image_file
    this.passengerImageFile = this.$parent.selectedDvir.passenger_side_image_file
    this.addedDefects = this.$parent.selectedDvir.dvir_defects
    this.startedAt = this.$parent.selectedDvir.started_at
    this.submittedAt = this.$parent.selectedDvir.submitted_at
    this.driverName = this.$parent.selectedDvir.user.name

    let xyCoordinate = this.addedDefects;
    console.log("COOR: " + JSON.stringify(xyCoordinate))
    if (xyCoordinate != null) {
      // this.addedDefects = xyCoordinate
      this.addedDefects.forEach(function(defect) {
        console.log('DEFECT_ID: ' + defect.dvir_defect_type_id)
        console.log('COORDINATES: ' +JSON.parse(JSON.stringify(defect.defect_areas)))
        localStorage.setItem('xyCoordinates_' + defect.dvir_defect_type_id, defect.defect_areas)
      })
    }
  }
}
</script>

<style>
.dvirImage {
  max-height: 50px !important;
  height: 50px !important;
  width: 70px !important;
}
</style>