<template>
  <Login />
</template>

<script>
import Login from '@/components/Login/Login.vue'

export default {
  name: 'HomeView',
  components: {
    Login
  },
  mounted() {
    console.log(localStorage.getItem('isLogin'))
    if (localStorage.getItem('isLogin') === 'true') {
      window.location.href = '/dashboard'
    }
  }
}
</script>
