<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Tap to mark the defect area</h3>
              <div id="mainHandler">
                <div id="imageHandler">
                  <img src="img/top-view-truck.png" alt="No image" id="markImage" class="markImage" width="150" title="Top view vehicle"/>
                  <div v-html="area"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                @click="saveDefectArea"
                v-if="isCreate"
            >
              Save
            </button>
            <div class="columns-2">
              <div>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="hidePreview"
                >
                  {{ isCreate ? 'Cancel' : 'Close'}}
                </button>
              </div>
              <div>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-red-300 hover:bg-red-700 sm:col-start-1 sm:mt-0"
                    @click="clearDefectArea"
                    :disabled="!isCreate"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImagePreview',
  components: {},
  props: [
    'area',
    'isCreate'
  ],
  data() {
    return {
      defectAreas: [],
      xyCoordinates: [],
      limitDefectArea: 1,
      defectAreaCount: 0,
    }
  },
  methods: {
    hidePreview() {
      this.$parent.showPreview = false

      // Will lose all defect areas
      if (this.isCreate) {
        localStorage.removeItem('xyCoordinates_' + this.$parent.selectedDefectType)
      }

    },
    saveDefectArea() {
      // Save to local storage

      // Hide modal
      this.$parent.showPreview = false
    },
    clearDefectArea() {
      localStorage.removeItem('xyCoordinates_' + this.$parent.selectedDefectType)
      document.querySelectorAll(".dot").forEach(el => el.remove());
      this.xyCoordinates = {}
      this.defectAreaCount = 0
    }
  },
  mounted() {
    let markImage = document.getElementById('markImage')
    let imageHandler = document.getElementById('imageHandler')
    const t = this
    if (this.isCreate) {
      markImage.addEventListener("click", function(event) {
        t.defectAreaCount = t.defectAreaCount + 1;

        if (t.defectAreaCount <= t.limitDefectArea) {
          let dot = document.createElement('div');
          dot.classList.add('dot')
          dot.innerHTML = '&bull;'

          var x = event.offsetX + 10;
          var y = event.offsetY + 20;

          console.log(x, y)

          dot.style.top = y + 'px'
          dot.style.left = x + 'px'
          imageHandler.classList.add('imageDefect_' + t.$parent.selectedDefectType)
          imageHandler.appendChild(dot)
          t.xyCoordinates = [{'x':x, 'y':y}]
          localStorage.setItem('xyCoordinates_' + t.$parent.selectedDefectType, JSON.stringify(t.xyCoordinates))
          // console.log(x , y, t.$parent.selectedDefectType)
        }
      });
    }

    let xyCoordinate = localStorage.getItem('xyCoordinates_' + this.$parent.selectedDefectType);
    console.log('XY: ' + xyCoordinate)
    if (xyCoordinate != null && xyCoordinate !== 'null') {
      this.xyCoordinates = JSON.parse(xyCoordinate)
      console.log(this.xyCoordinates)
      this.xyCoordinates.forEach(function(coordinate) {
        console.log('COORDINATES: ' +JSON.stringify(coordinate))
        let dot = document.createElement('div');
        dot.classList.add('dot')
        dot.innerHTML = '&bull;'

        var x = coordinate.x;
        var y = coordinate.y;

        console.log("Your screen resolution is: " + x / window.screen.width  + "x" + y / window.screen.height );

        dot.style.top = y + 'px'
        dot.style.left = x + 'px'
        imageHandler.classList.add('imageDefect_' + t.$parent.selectedDefectType)
        imageHandler.appendChild(dot)
      })
    }
  }
}
</script>
<style>
.markImage {
  width: 500px !important;
}
.dot {
  font-size: 5em;
  font-weight: bolder;
  color: red;
  z-index: 9999999;
  position: absolute
}
</style>