<template>
  <PageTitle />
  <VehicleDetails />
</template>

<script>

import VehicleDetails from "@/components/Vehicle/Details.vue";
import PageTitle from "@/components/Common/PageTitle.vue";

export default {
  name: 'VehicleDetailView',
  components: {
    PageTitle,
    VehicleDetails
    //
  },
  mounted() {
    // Set selected vehicle
    this.$store.dispatch('vehicle/selected')

    // Set page title
    this.$store.dispatch('setPageTittle', 'Vehicle Information', '/dashboard')
  }
}
</script>