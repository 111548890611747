<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <div class="bg-white-300">
      <img class="object-none h-48 w-96" :src="($store.state.vehicle.selectedVehicle.image == null || $store.state.vehicle.selectedVehicle.image === ''  ? 'img/no-image.png' : $store.state.vehicle.selectedVehicle.image)" />
    </div>

    <div class="p-5" v-if="$store.state.vehicle.hasSelected">
      <div>
        <h2 class="mt-1 text-2xl text-center">
          {{ $store.state.vehicle.selectedVehicle.unit_number}} - {{ $store.state.vehicle.selectedVehicle.year }}
          {{ $store.state.vehicle.selectedVehicle.make }}
          {{ $store.state.vehicle.selectedVehicle.model }}
        </h2>

<!--          <p class="mt-1 text-4xl uppercase">{{ $store.state.vehicle.selectedVehicle.state_registered}}</p>
          <p class="mt-1 text-5xl uppercase font-bold">{{ $store.state.vehicle.selectedVehicle.license_plate_number}}</p>-->
          <div class="license-plate justify-center m-auto">
            <div class="inner-border"></div>
            <div class="mt-1 text-4xl uppercase utah-text">{{ $store.state.vehicle.selectedVehicle.state_registered}}</div>
            <div class="mt-1 text-5xl uppercase font-bold number-text">{{ $store.state.vehicle.selectedVehicle.license_plate_number}}</div>
            <div class="circle top-left"></div>
            <div class="circle top-right"></div>
            <div class="circle bottom-left"></div>
            <div class="circle bottom-right"></div>
          </div>

      </div>
    </div>

    <div>
      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">Vehicle Type</h3>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{ $store.state.vehicle.selectedVehicle.make }} {{ $store.state.vehicle.selectedVehicle.model }}</p>
      </div>

      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">VIN Number</h3>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{ $store.state.vehicle.selectedVehicle.vin_number }}</p>
      </div>

      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">Unit Number</h3>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{ $store.state.vehicle.selectedVehicle.unit_number }}</p>
      </div>

      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">Last Odometer Reading</h3>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{ $store.state.vehicle.selectedVehicle.odometer }}</p>
      </div>

      <div class="border-t-gray-600 border m-3.5"></div>

      <PastDvirPanel />
    </div>
  </div>
</template>

<script>
import PastDvirPanel from "@/components/Dvir/List.vue";

export default {
  data() {
    return {
      dvirList: [],
      count: 1,
    }
  },
  name: 'VehicleDetails',
  components: {PastDvirPanel},
  methods: {
    //
  },
  created() {
    this.dvirList = JSON.parse(localStorage.getItem('dvirList'))
    // console.log('dvirList: '+ JSON.stringify(this.dvirList))
    this.count = this.dvirList.data.length
  },
  mounted() {
    this.$store.dispatch('vehicle/selected')
  }
}
</script>