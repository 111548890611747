<template>
  <nav class="flex items-center justify-between flex-wrap bg-white-500 p-4">
    <div class="flex items-center flex-shrink-0 text-gray-800 mr-6">
      <span class="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-300 mr-4">
        <img class="h-full w-full text-gray-800" :src="($store.state.login.userData.image === '' ? 'img/no-photo.png' : $store.state.login.userData.image )"/>
      </span>
      <span class="font-semibold text-xl tracking-tight mr-3">{{ $store.state.login.userData.first_name }} {{ $store.state.login.userData.last_name}}</span>
    </div>
      <div>
        <a href="#" @click="logout" class="inline-block text-sm px-4 py-2 leading-none rounded hover:border-transparent hover:text-teal-500 hover:bg-white mt-1 lg:mt-0 text-green-800 text-xl">
          <i class="fa-solid fa-right-from-bracket"></i>
        </a>
      </div>
  </nav>
</template>

<script>
export default {
  name: 'HeaderPanel',
  data() {
    return {
      'userData': JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    logout() {
      this.$store.state.login.isLogin = false
      this.$store.state.login.apiToken = ''
      localStorage.clear()
      window.location.href = '/'
    }
  },
  mounted() {
    this.$store.state.login.userData = JSON.parse(localStorage.getItem('userData'))
  }
}
</script>