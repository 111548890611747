<template>
    <div class="mt-5 p-5 bg-white">
      <div class="">
        <div class="text-center">
            <h1 class="text-8xl text-green-600" v-if="this.$parent.safeToDrive">
              <i class="fa-sharp fa-solid fa-file-certificate" v-if="this.$parent.safeToDrive"></i>
            </h1>
          <div class="flex m-3.5" v-if="!this.$parent.safeToDrive">
            <div class="mr-4 flex-shrink-0 text-8xl text-green-600">
              <i class="fa-solid fa-dumpster-fire" v-if="!this.$parent.safeToDrive"></i>
            </div>
            <div class="text-left">
              <p class="mt-1 text-red-600">Please notify your manager as soon as possible to make alternate vehicle arrangements.</p>
            </div>
          </div>
        </div>
        <div class="text-center clear-both">
          <h1 class="uppercase text-4xl m-0">{{ (this.$parent.safeToDrive ? 'Safe to Drive' : 'Not Safe to Drive' ) }}</h1>
          <p class="text-md">
            {{ $store.state.vehicle.selectedVehicle.unit_number}} - {{ $store.state.vehicle.selectedVehicle.year }}
            {{ $store.state.vehicle.selectedVehicle.make }}
            {{ $store.state.vehicle.selectedVehicle.model }}
          </p>
        </div>
        <div class="text-center mt-5">
          <p class="mt-1 text-md">
            I, {{ $store.state.login.userData.first_name }} {{ $store.state.login.userData.last_name}}, hereby certify that <strong>{{ $store.state.vehicle.selectedVehicle.unit_number}} - {{ $store.state.vehicle.selectedVehicle.year }}
            {{ $store.state.vehicle.selectedVehicle.make }}
            {{ $store.state.vehicle.selectedVehicle.model }} </strong> is <strong>{{ (this.$parent.safeToDrive ? 'Safe to Drive' : 'Not Safe to Drive' ) }}</strong>
          </p>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'CertifyPanel',
  components: {

  },
  methods: {
    //
  },
  mounted() {
    this.$store.state.login.userData = JSON.parse(localStorage.getItem('userData'))
  }
}
</script>