<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <div class="mx-auto py-8 max-w-sm w-full bg-white logo-container rounded-3xl drop-shadow-lg" v-if="$store.state.vehicle.hasSelected">
        <h4 class="text-lg font-bold text-center pb-2" style="padding-bottom:8px;" v-if="!$store.state.vehicle.selectedVehicle.rental">Current Vehicle</h4>
        <h4 class="text-lg font-bold text-center pb-2" style="padding-bottom:8px;" v-if="$store.state.vehicle.selectedVehicle.rental">Rental Vehicle</h4>
        <a href="/vehicle-details" class="flex justify-center align-middle">
          <div class="mr-4 flex-shrink-0 self-center text-5xl text-green-800">
            <img class="w-24 text-gray-800" :src="($store.state.vehicle.selectedVehicle.image == null || $store.state.vehicle.selectedVehicle.image === ''  ? 'img/no-image.png' : $store.state.vehicle.selectedVehicle.image)" />
          </div>
          <div>
            <p class="mt-1 text-md">{{ $store.state.vehicle.selectedVehicle.unit_number }} - {{ $store.state.vehicle.selectedVehicle.year }} {{ $store.state.vehicle.selectedVehicle.make }} {{ $store.state.vehicle.selectedVehicle.model }}</p>
            <p class="mt-1 text-md">{{ $store.state.vehicle.selectedVehicle.state_registered }} {{ $store.state.vehicle.selectedVehicle.license_plate_number}} </p>
            <p class="mt-1 text-md">{{ $store.state.vehicle.selectedVehicle.vin_number }} </p>
            <p class="mt-1 text-md" v-if="$store.state.vehicle.selectedVehicle.rental">{{ $store.state.vehicle.selectedVehicle.leasing_agency }} </p>
          </div>
        </a>
    </div>
    <div class="mx-auto py-8 max-w-sm w-full bg-white logo-container rounded-3xl drop-shadow-lg" v-if="!$store.state.vehicle.hasSelected">
      <div class="flex p-5">
        <div class="rounded-md bg-red-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">{{ isChoose ? noSelectedMsg : $store.state.vehicle.noSelectedMsg }}</h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: [
    'isChoose',
    'noSelectedMsg'
  ],
  name: 'SelectedVehiclePanel',
  methods: {
    //
  },
  mounted() {
    this.$store.dispatch('vehicle/selected')
    this.$store.dispatch('dvir/get', 1)
  }
}
</script>