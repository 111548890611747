<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <div class="mx-auto py-8 max-w-sm flex justify-center w-2/4 bg-white logo-container rounded-3xl drop-shadow-lg">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1891 691" class="h-8">
        <path class="cls-4" d="M262.72-.51c2.67.68,4.82,1.71,7.07,1.98,16.65,2.04,33.32,3.92,50.18,5.88v39.47c-7.86-2.65-15.52-5.81-23.48-7.84-50.96-13.05-100.69-10.16-147.58,14.79-46.56,24.78-72.54,65.08-81.54,116.39-6.78,38.7-3.75,76.85,11.7,113.27,22.39,52.76,62.57,83.83,118.71,93.93,39.46,7.1,77.96,1.45,115.64-11.19,2.02-.68,4.04-1.35,6.8-2.28,0,13.05.06,25.47-.12,37.89-.01.88-1.71,2.33-2.79,2.52-59.01,10.12-117.93,13.8-176.04-4.96-57.62-18.6-101.01-53.73-124.7-110.63C3.31,256.87-.57,223.46,1.7,189.26c2.13-32.05,10.39-62.37,26.93-90.11C52.95,58.35,88.66,31.56,132.85,15.49c20.43-7.43,41.46-12.32,63.19-14.01,1.07-.08,2.09-.75,3.14-1.57,21.03-.42,42.05-.42,63.54-.42Z"></path>
        <path class="cls-1" d="M-.82,436.96c1.01-7.49,1.87-14.53,3.06-21.52,3.12-18.3,8.26-35.99,16.29-52.77.55-1.15,1.34-2.18,2.35-3.81,2.65,2.9,4.81,5.63,7.33,7.97,7.19,6.69,14.39,13.37,21.87,19.72,2.24,1.91,3.02,3.35,2.1,6.21-7.48,23.24-9.98,47.14-9.05,71.44,1.62,42.42,13.54,81.38,40.51,114.71,25,30.9,57.7,49.73,96.29,58.58,27.09,6.21,54.43,6.2,81.92,3.46,18.81-1.88,37.13-5.69,54.93-12.04.89-.32,1.9-.29,3.15-.47v22.86c-9.14,1.65-18.36,3.59-27.66,4.93-25.23,3.64-50.59,6.09-76.11,5.11-46.67-1.79-91.04-11.92-130.7-37.83-39.4-25.74-65.67-61.44-78.04-106.83-3.78-13.87-5.18-28.39-7.95-42.9-.29-12.31-.29-24.33-.29-36.82Z"></path>
        <path class="cls-8" d="M610.08,558.49c0,34.32,0,68.13,0,102.42h-24.35v-196.09c7.45,0,14.88-.16,22.28.15,1.33.06,3.03,1.82,3.79,3.22,16.38,30.14,32.64,60.34,48.93,90.52,9.17,17,18.35,33.99,27.54,50.98.3.56.78,1.03,1.59,2.08,4.8-8.79,9.42-17.18,13.97-25.62,21.12-39.12,42.26-78.23,63.28-117.41,1.53-2.86,3.14-4.23,6.52-4.02,4.98.31,10.01.36,14.99,0,4.48-.32,5.58,1.29,5.57,5.66-.16,44.82-.09,89.64-.09,134.47,0,16.83,0,33.66,0,50.49,0,1.8,0,3.59,0,5.71h-24.55v-144.51c-.45-.18-.91-.36-1.36-.53-25.93,47.92-51.86,95.84-78.49,145.05-26.55-49.07-52.51-97.04-78.46-145.02-.38.1-.75.2-1.13.3,0,13.89,0,27.77,0,42.16Z"></path>
        <path class="cls-2" d="M476.14,204.59c15.02,1.79,28.95,5.52,40.93,14.42,26.24,19.51,26.29,53.04-.21,72.17-7.42,5.36-16.09,8.99-24.01,13.31,3.28,1.33,7.2,2.69,10.93,4.46,19.67,9.33,31.72,23.98,33.6,46.45,1.27,15.25,3.69,30.41,5.58,45.61.12.97.02,1.97.02,3.29h-36.27c-1.17-10.03-2.34-20.07-3.5-30.12-.88-7.61-1.57-15.24-2.61-22.83-2.67-19.53-12.13-30.06-31.66-33.13-11.57-1.82-23.47-1.56-35.91-2.27v88.42h-35.38v-199.83c25.94,0,52,0,78.51.04M479.54,288.54c2.41-1.11,4.97-1.98,7.21-3.36,11.58-7.09,17.13-17.72,16.02-31.04-1.06-12.73-10.33-18.82-21.43-22.2-15.73-4.79-31.91-2.18-48.17-2.92v62.47c5.57,0,11.03.3,16.45-.07,9.74-.66,19.45-1.71,29.91-2.89Z"></path>
        <path class="cls-3" d="M1263.3,204.98c.75-.65,1.36-1.04,1.98-1.04,25.32.17,50.66-.19,75.94.79,15.66.61,30.43,5.59,43.11,15.4,23.37,18.07,24.33,49.74,1.45,68.38-6.96,5.67-15.69,9.17-23.64,13.61-1.24.7-2.64,1.12-4.52,1.89,5.02,2.12,9.57,3.74,13.85,5.9,17.82,8.99,29.21,22.65,31.4,43.17,1.75,16.39,3.96,32.73,5.95,49.09.08.62,0,1.25,0,2.17h-36.3c-1.04-8.72-2.12-17.58-3.15-26.43-1.1-9.43-2.08-18.87-3.23-28.29-1.93-15.76-10.94-26.05-26.33-30.37-13.39-3.76-27.02-2.41-41.1-2.64v87.66h-35.55c0-66.33,0-132.59.13-199.29M1365.15,244.15c-3.46-3.05-6.46-7.15-10.49-8.91-6.3-2.75-13.21-5.01-20.01-5.5-11.84-.87-23.8-.24-35.85-.24v61.43c12.27,0,24.24.3,36.18-.16,4.37-.17,8.88-1.61,12.97-3.33,17.81-7.49,25.78-27.16,17.2-43.28Z"></path>
        <path class="cls-9" d="M795.84,372.02c15.98,7.47,32.02,6.65,48.15,2.28,1.16-.32,2.72-2.14,2.73-3.28.18-16.31.12-32.63.12-49.71h-29.15v-24.95h60.1v102.46c-26.32,5.18-52.54,11.92-79.77,5.94-33.13-7.27-55.57-27.04-67.06-58.71-9.91-27.32-10.71-55.28-.94-82.76,13.02-36.62,40.26-55.86,78.21-60.81,18.11-2.36,35.88.11,53.46,4.34,1.52.37,3.82,2.12,3.85,3.29.3,10.62.18,21.25.18,31.71-7.58-2.98-14.68-6.68-22.23-8.59-41.7-10.54-78.87,16.32-82.37,59.19-1.59,19.45.62,38.26,10.17,55.66,5.66,10.32,13.5,18.52,24.54,23.93Z"></path>
        <path class="cls-9" d="M909.11,291.28c3.39-34.45,17.84-61.65,48.78-78.16,21.34-11.39,44.4-13.41,68.04-10.21,7.25.98,14.42,2.55,21.57,4.11,1.26.28,3.2,1.79,3.22,2.77.23,10.76.15,21.52.15,32.98-4.03-2.15-7.26-4.11-10.68-5.65-32.22-14.57-84.18-5.1-93.21,50.1-3.04,18.59-1.27,36.9,6.22,54.38,11.05,25.8,33.32,38.75,61.22,35.58,4.94-.56,9.85-1.64,14.68-2.87,1.15-.29,2.73-2.12,2.74-3.25.12-16.16.01-32.32-.07-48.48,0-.28-.31-.57-.74-1.28h-28.04v-24.92h59.95v102.49c-24.59,4.62-48.87,11.09-74.14,6.89-39.85-6.62-64.45-30.2-75.65-68.45-4.36-14.88-5.03-30.17-4.04-46.02Z"></path>
        <path class="cls-5" d="M1230.04,389.51v14.77h-125.37v-200.03h125.05v26.94h-89.41v56.16h72.5v25.04h-72.38v64.98h89.61v12.14Z"></path>
        <path class="cls-6" d="M680.18,404.64c-34.47,0-68.43,0-102.73,0v-200.38h124.97v26.93h-89.48v56.34h72.79v24.87h-72.66v64.98h89.31v27.28h-22.19Z"></path>
        <path class="cls-1" d="M545.09,603.31c-6.38,20-16.91,36.6-34.51,47.96-35.43,22.86-96.07,16.87-118.12-38.04-13.61-33.88-13.43-68.44.87-102.13,24.89-58.59,97.98-61.25,131.87-23.8,13.27,14.67,20.49,32.21,23.67,51.44,3.57,21.62,2.62,43.09-3.78,64.58M504.62,501.61c-14.84-16.96-34.66-22.65-54.4-15.27-16.51,6.17-26,19.12-32.07,34.91-7.79,20.25-8.64,41.29-5.59,62.47,2.13,14.8,6.92,28.7,16.43,40.73,13.15,16.64,35.5,23.04,55.22,15.29,16.61-6.53,25.92-19.8,31.92-35.73,7.02-18.63,7.88-38.07,5.7-57.57-1.79-16.05-6.47-31.28-17.2-44.81Z"></path>
        <path class="cls-1" d="M1306.14,659.15c-1.2,1.05-2.34,2.09-3.54,2.16q-14.46.81-22.46-11.51c-28.69-44.11-57.37-88.22-86.06-132.33-.59-.9-1.38-1.67-2.84-3.4,0,25.43,0,49.68,0,73.92s0,48.55,0,73.11h-19.77v-195.82c5.56,0,11.02-.23,16.43.16,1.37.1,2.93,1.96,3.84,3.37,19.13,29.66,38.17,59.39,57.25,89.08,11.78,18.33,23.59,36.64,36.23,56.29.31-3.88.69-6.36.69-8.83.04-44.66.13-89.32-.1-133.97-.03-5.27,1.55-6.66,6.47-6.21,4.41.4,8.89.08,13.9.08,0,64.88,0,129.15-.03,193.89Z"></path>
        <path class="cls-1" d="M865.92,618.49c0,12.83-.13,25.17.08,37.49.07,3.99-.89,5.7-5.24,5.49-6.95-.34-13.93-.09-21.27-.09v-196.08c1.12-.15,2.37-.48,3.61-.46,20.98.2,41.99-.05,62.95.84,16.25.69,31.32,5.95,44.53,15.83,25.37,18.97,26.9,54.05,13.48,76.55-11.76,19.73-30.43,29.05-52.17,32.12-12.61,1.79-25.57,1.09-38.37,1.49-2.3.07-4.6.01-7.59.01,0,9.12,0,17.72,0,26.83M942.79,540.34c.31-1.29.66-2.58.94-3.88,4.55-21.05-5.68-39.93-25.52-47.12-16.95-6.14-34.45-2.95-52-3.88v86.51c14.03-.55,27.9-.04,41.44-1.9,17.06-2.34,29.19-12.16,35.13-29.74Z"></path>
        <path class="cls-8" d="M984.89,632.13c19.46-54.27,38.83-108.15,58.02-162.09,1.49-4.2,3.57-5.67,7.93-5.31,5.63.47,11.31.49,16.97.36,3.43-.07,4.88,1.37,6,4.51,9.15,25.9,18.48,51.73,27.76,77.58,13.15,36.66,26.3,73.32,39.44,109.99.43,1.2.71,2.46,1.2,4.22-8.48,0-16.45.17-24.41-.17-1.3-.05-3.13-2.12-3.66-3.62-3.93-11.32-7.7-22.7-11.28-34.13-1.02-3.25-2.61-4.34-6.05-4.32-25.66.15-51.33.15-76.99,0-3.64-.02-5.29,1.12-6.32,4.61-3.33,11.33-7.09,22.53-10.49,33.85-.87,2.89-2.23,3.94-5.27,3.86-7.43-.21-14.88-.07-23.33-.07,3.66-10.23,7-19.56,10.47-29.27M1055.22,495.08c-11.14,34.59-22.27,69.17-33.57,104.26h73.65c-12.19-37.76-24.24-75.1-36.29-112.43-.34,0-.69.02-1.03.03-.85,2.46-1.7,4.92-2.75,8.15Z"></path>
        <path class="cls-8" d="M1407.32,661.38c-2.12,0-3.75,0-5.91,0-.1-2.15-.24-3.91-.24-5.67-.02-22.32.05-44.65-.1-66.97-.02-2.51-.68-5.3-1.89-7.49-19.5-35.25-39.15-70.42-58.74-105.62-1.77-3.19-3.4-6.45-5.7-10.81,6.09,0,11.46.72,16.55-.14,11.9-2.02,17.32,3.18,21.96,14,12.13,28.29,25.84,55.91,38.93,83.78.62,1.32,1.41,2.57,2.56,4.64,3.02-6.31,5.76-11.91,8.39-17.56,12.28-26.42,24.6-52.81,36.7-79.31,1.77-3.87,3.9-5.63,8.25-5.49,8.28.27,16.57.08,26.07.08-1.35,2.95-2.01,4.72-2.92,6.36-20.26,36.16-40.58,72.28-60.74,108.49-1.45,2.61-2.37,5.89-2.4,8.87-.2,22.32-.21,44.65-.03,66.97.04,4.39-.9,6.42-5.77,5.97-4.79-.44-9.65-.1-14.97-.1Z"></path>
        <path class="cls-8" d="M1687.19,529.46c20.14,31.31,40.11,62.31,60.07,93.3l.94-.32v-113.78c4.54,0,8.67-.21,12.76.14,1.07.09,2.35,1.74,2.89,2.96.49,1.12.12,2.62.12,3.95,0,45.66,0,91.32,0,136.97,0,2.12,0,4.24,0,6.89-4.73,0-8.87.24-12.95-.14-1.31-.12-2.82-1.63-3.65-2.89-20.31-31.07-40.54-62.2-60.79-93.3-3.34-5.14-6.75-10.23-11.18-15.12v111.18h-15.95c0-1.91,0-3.53,0-5.15,0-46.49.07-92.98-.1-139.47-.02-4.82,1.33-6,6.12-6.24,6.26-.31,10.24,1.33,13,7.21,2.25,4.78,5.65,9.02,8.72,13.81Z"></path>
        <path class="cls-8" d="M1865.1,507.42c7.72,1.05,14.94,2.12,22.18,3.11,2.83.38,3.9,1.78,3.8,4.68-.19,5.58-.06,11.17-.06,16.32-7.17-2.16-13.78-5.09-20.67-6.07-23.87-3.39-43.57,7.17-52.74,29.33-9.7,23.42-8.61,46.9,4.72,68.9,9.57,15.79,28.69,23.23,48.41,19.29,6.72-1.34,13.18-4.06,20.22-6.29,0,5.75.08,11.98-.09,18.21-.02.83-1.16,2.14-2,2.35-23.6,5.96-46.83,7.17-68.23-7.11-16.79-11.2-25.52-27.73-28.92-47.14-3.24-18.5-2.37-36.83,4.93-54.41,11.05-26.62,33.1-41.18,61.96-41.2,2,0,4,.01,6.48.03Z"></path>
        <path class="cls-8" d="M269.98,268.52c-19.87-1.95-39.3-2.15-58.7-.04-30.17,3.28-58.61,12.03-84.54,28.07-3.61,2.23-5.71,2.36-7.89-1.53-3.88-6.91-8.05-13.66-12.44-21.06,69.79-32.94,141.21-29.13,213.55-16.63v22.19c-16.58-3.68-33.05-7.33-49.97-11Z"></path>
        <path class="cls-7" d="M1604.34,566.49v-57.4h20.33v150.06h-20.33c0-30.66,0-61.41,0-92.66Z"></path>
        <path class="cls-8" d="M1507.53,681.88c-3.1,9.07-6.15,10.26-19.41,7.58,2.16-11.54,4.33-23.09,6.49-34.64.03-.16.1-.32.14-.48,1.01-4.14.75-9.91,3.39-11.98,2.72-2.14,8.27-.72,12.58-.8,3.31-.06,6.62-.01,10.86-.01-4.78,13.74-9.34,26.86-14.05,40.33Z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoPanel',
  methods: {
    //
  },
}
</script>