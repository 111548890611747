import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import Dashboard from "@/views/Dashboard.vue"
import VehicleDetailView from "@/views/VehicleDetails.vue"
import SelectVehicleView from "@/views/SelectVehicle.vue"
import CreateDvirView from "@/views/CreateDvir.vue"
import ViewDvirView from "@/views/ViewDvir.vue"


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/vehicle-details',
    name: 'vehicleDetails',
    component: VehicleDetailView
  },
  {
    path: '/vehicle-select',
    name: 'vehicleSelect',
    component: SelectVehicleView
  },
  {
    path: '/vehicle-dvir-create',
    name: 'vehicleDvirCreate',
    component: CreateDvirView
  },
  {
    path: '/vehicle-dvir-view',
    name: 'vehicleDvirView',
    component: ViewDvirView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
