<template>
  <nav class="flex items-center justify-between flex-wrap bg-white-500 p-4">
    <div class="flex items-center flex-shrink-0 text-gray-800 mr-6">
      <a :href="$store.state.parentPage" v-if="$store.state.isRefresh">
        <i class="fa-solid fa-chevron-left"></i>
        <span class="font-semibold text-xl tracking-tight mr-3 pl-5">{{ $store.state.pageTitle}}</span>
      </a>

      <a href="#" v-if="!$store.state.isRefresh" @click="closePage">
        <i class="fa-solid fa-chevron-left"></i>
        <span class="font-semibold text-xl tracking-tight mr-3 pl-5">{{ $store.state.pageTitle}}</span>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PageTitle',
  data() {
    return {
      // 'userData': JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    closePage() {
      //TODO: Make dynamic page title to go back
      this.$store.dispatch('setPageTittle', this.$store.state.parentPageTitle, '/dashboard')
      this.$store.dispatch('setParentPage', '/dashboard')
      this.$store.state.isRefresh = true
      this.$parent.isCertifyPage = false
    }
  },
  mounted() {
    // this.$store.state.login.userData = JSON.parse(localStorage.getItem('userData'))
  }
}
</script>