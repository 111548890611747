<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">Past DVIRs</h3>
        <div class="mb-10" v-if="$parent.count > 0">
          <ul role="list" class="divide-y divide-gray-100 bg-white p-5 mb-1">
            <li class="flex items-center justify-between gap-x-6 py-5 bg-white-500" v-for="(dvir, index) in $parent.dvirList.data" :key="index">
              <div class="flex min-w-0 gap-x-4">
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ formatDate(dvir.created_at) }}</p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">DRIVER NAME: {{ dvir.user.name }}</p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">ODOMETER: {{ dvir.odometer }}</p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">STATUS:
                    <span class="text-green-800 font-bold" v-if="dvir.safe_to_drive">Safe to Drive</span>
                    <span class="text-red-600 font-bold" v-if="!dvir.safe_to_drive">Not Safe to Drive</span>
                  </p>
                </div>
              </div>
              <a
                  href="#"
                  class="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  @click="viewDvir(dvir)"
              >
                View
              </a>
            </li>
          </ul>
          <TailwindPagination
              :data="$parent.dvirList"
              @pagination-change-page="getResults"
          />
       </div>

        <div class="mb-10 text-center text-red-600 font-bold" v-if="$parent.count <= 0">
          No record found
        </div>
      </div>
  </div>
</template>

<script>
import { TailwindPagination } from 'laravel-vue-pagination';
import axios from "axios";
import {API_URL} from "@/config/constants";
export default {
  name: 'PastDvirPanel',
  components: {
    TailwindPagination
  },
  data() {
    return {
      dvirList: {},
      getPagination: '',
      page: 1,
      count: 1
    }
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
    async getResults(page) {
      console.log('PAGE: ' + page)
      const t = this
      let data = {
        vehicleId: this.$store.state.vehicle.selectedVehicle.id,
        page: page
      }
      // console.log(data)
      const config = {
        headers: {
          'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios.post(API_URL + 'dvir/get', data, config)
          .then(function (response) {
            localStorage.setItem('dvirList', JSON.stringify(response.data.dvir))
            t.$parent.dvirList = response.data.dvir
            t.$parent.count = t.$parent.dvirList.data.length
            // console.log('RESPONSE: ' + JSON.stringify(response.data.dvir))
          }).catch((error) => {
            console.log('ERROR: ' + error)
          });
    },
    viewDvir(selectedDvir) {
      localStorage.setItem('selectedDvir', JSON.stringify(selectedDvir))
      window.location.href = '/vehicle-dvir-view'
    }
  },
  created() {
    this.$store.dispatch('vehicle/selected')
  },
  mounted() {

  }
}
</script>