<template>
    <div class="mx-auto max-w-7xl px-6 lg:px-8 text-green-800">
      <div class="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-2">
        <div class="bg-white-400/5 p-8 sm:p-10 text-center text-2xl bg-white m-1.5 rounded-tl-2xl drop-shadow-lg px-2" v-if="$store.state.vehicle.hasSelected">
          <a href="/vehicle-dvir-create">
            <i class="fa-regular fa-rectangle-list"></i>
            <p>DVIRs</p>
          </a>
        </div>
        <div class="bg-white-400/5 p-8 sm:p-10 text-center text-2xl bg-gray-200 m-1.5 rounded-tl-2xl drop-shadow-lg px-2" v-if="!$store.state.vehicle.hasSelected">
          <a href="#">
            <i class="fa-regular fa-rectangle-list"></i>
            <p>DVIRs</p>
          </a>
        </div>
        <div class="bg-white-400/5 p-8 sm:p-10 text-center text-2xl bg-white m-1.5 rounded-tr-2xl drop-shadow-lg px-2">
          <a href="/vehicle-select">
            <i class="fa-sharp fa-solid fa-truck-fast"></i>
            <p>Choose Vehicle</p>
          </a>
        </div>
        <div class="bg-white-400/5 p-8 sm:p-10 text-center text-2xl bg-gray-200 m-1.5 rounded-bl-2xl drop-shadow-lg px-2">
          <a href="">
            <i class="fa-solid fa-file"></i>
            <p class="text-center">Documents</p>
          </a>
        </div>
        <div class="bg-white-400/5 p-8 sm:p-10 text-center text-2xl bg-gray-200 m-1.5 rounded-br-2xl drop-shadow-lg">
          <a href="">
            <i class="fa-solid fa-car-burst"></i>
            <p>Accidents</p>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'MainMenu',
  methods: {
    //
  },
  created() {
    this.$store.dispatch('dvir/getDvirDefectCategories', {})
  }
}
</script>